import { useApolloClient } from "@apollo/client"
import { Link, navigate } from "gatsby"
import Cookie from "js-cookie"
import { Button } from "nzk-react-components"
import React, { useEffect } from "react"
import styled from "styled-components"
// @ts-ignore
import { useShoppingCart } from "use-shopping-cart"
import CHECKOUT_SESSION_CANCEL from "../graphql/shop_checkoutSessionCancel.graphql"

const Wrapper = styled.div`
  color: #662d91;
  min-height: 500px;
`

const Title = styled.div`
  font-size: 26px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  font-weight: bold;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > * {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  > :last-child {
    margin-right: 0;
  }
  margin-bottom: 30px;
`

const OrderFailed = () => {
  const client = useApolloClient()
  const { clearCart } = useShoppingCart()

  const checkoutSessionCancel = async () => {
    const orderId = Cookie.get("nzk-order-checkout")
    if (!orderId) return
    await client.mutate({
      mutation: CHECKOUT_SESSION_CANCEL,
      variables: {
        orderId,
      },
    })
    // const { shop_checkoutSessionCancel: order } = data
    Cookie.remove("nzk-order-checkout")
  }

  useEffect(() => {
    checkoutSessionCancel()
  }, [])

  const onCancel = () => {
    clearCart()
    navigate("/shop")
  }

  return (
    <Wrapper>
      <Title>Checkout cancelled</Title>
      <Actions>
        <Link to="/shop/checkout">
          <Button size="regular" theme="purple">
            Return to basket
          </Button>
        </Link>
        <Link to="/shop">
          <Button size="regular" theme="purple">
            Continue shopping
          </Button>
        </Link>
        <Button size="regular" theme="primary" onClick={onCancel}>
          Cancel order
        </Button>
      </Actions>
    </Wrapper>
  )
}

export default OrderFailed
