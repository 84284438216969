import React from 'react'
import OrderFailed from '../../../components/pages/Shop/OrderFailed'
import ShopLayout from '../../../layouts/ShopLayout'

const OrderFailedPage = () => <ShopLayout>
  <OrderFailed />
</ShopLayout>


export default OrderFailedPage
